<template>
  <div class="padding24 color_white">
    <div class="header-box">
      <a-select default-value="1" v-model="isParent" style="width: 120px" @change="handleChange">
        <a-select-option :value="1">一级分类</a-select-option>
        <a-select-option :value="0">二级分类</a-select-option>
      </a-select>
      <a-button type="primary" @click="addClick">
        添加商品分类
      </a-button>
    </div>
    <a-table
      :columns="GoodsTypesColumn"
      :data-source="tabledData"
      :pagination="false"
      :loading="tableLoading"
      :row-key="record => record.id"
    >
      <span slot="number" slot-scope="number, record, index">
        <span>{{ (pageNo - 1) * pageSize + index + 1 }}</span>
      </span>
      <span slot="state" slot-scope="state, record">{{state === 1 ? '显示' : '隐藏'}}</span>
      <span slot="action" class="action" slot-scope="state, record">
        <a v-if="operation.edit" @click="editClick(record)">编辑</a>
        <a-popconfirm
          title="删除成功的商品分类无法撤销和恢复，请谨慎操作。"
          ok-text="确定"
          cancel-text="取消"
          @confirm="delClick(record)"
        >
          <a v-if="operation.del">删除</a>
        </a-popconfirm>
        <a-popconfirm
          :title="`${record.state === 1 ? '修改后在APP中不展示分类信息' : '修改后将在APP中会展示分类信息'} `"
          ok-text="确定"
          cancel-text="取消"
          @confirm="showClick(record)"
        >
          <a v-if="operation.show">{{record.state === 1 ? '隐藏' : '显示'}}</a>
        </a-popconfirm>
      </span>
    </a-table>
    <MyPagination
      :count="total"
      :pageNo="pageNo"
      @showSizeChangeFn="paginationCallBack"
      v-show="!tableLoading"
    />
    <AddGoodsTypes :visible="modalVisible" :record="curRecord" @cancel="modalVisible=false" @ok="handleOk"/>
  </div>
</template>
<script>
import { codeFn } from '@/utils/tools'
import MyPagination from '@/components/pagination/MyPagination'
import { GoodsTypesColumn } from '../constants'
import AddGoodsTypes from './components/addGoodsTypes'
import { getGoodsCategoryListAPI, removedGoodsCategoryAPI, delGoodsCategoryAPI } from '@/request/api/physicalCard'
export default {
  name: 'GoodsTypeList',
  components: { MyPagination, AddGoodsTypes },
  data() {
    return {
      operation: {
        add: false,
        edit: false,
        del: false,
        show: false
      },
      GoodsTypesColumn,
      tabledData: [],
      tableLoading: false,
      total: 0,
      pageNo: 1,
      pageSize: 20,
      isParent: 0, // 1一级分类 0 二级分类
      modalVisible: false,
      curRecord: {},
    }
  },
  created() {
    this.operation.add = codeFn("addGoodsTypes")
    this.operation.edit = codeFn("editGoodsTypes")
    this.operation.del = codeFn("showGoodsTypes")
    this.operation.show = codeFn("delGoodsTypes")
    this.fetchData()
  },
  methods: {
    handleChange() {
      this.pageNo = 1
      this.fetchData()
    },
    async fetchData() {
      this.tableLoading = true
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        isParent: this.isParent
      }
      const { code, data } = await getGoodsCategoryListAPI(params)
      this.tableLoading = false
      if (code !== 200) return
      this.total = data.count
      this.tabledData = data.data
    },
    // 分页功能切换的回调
    paginationCallBack(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize
      this.fetchData()
    },
    editClick(record) {
      this.curRecord = record
      this.modalVisible = true
    },
    async delClick(record) {
      const { code } = await delGoodsCategoryAPI({id: record.id})
      if (code !== 200) return
      this.$message.success('删除成功')
      this.fetchData()
    },
    async showClick(record) {
      const params = {
        id: record.id,
        state: record.state === 1 ? 2 : 1
      }
      const { code } = await removedGoodsCategoryAPI(params)
      if (code !== 200) return
      this.$message.success('修改成功')
      this.fetchData()
    },
    addClick() {
      this.curRecord = {}
      this.modalVisible = true
    },
    handleOk() {
      this.modalVisible=false
      this.fetchData()
    }
  }
}
</script>
<style lang="less" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.action {
  a {
    margin-right: 10px;
  }
}
</style>