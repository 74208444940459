<template>
  <a-modal :visible="visible" title="添加商品分类" destroyOnClose @ok="handleOk" @cancel="() => $emit('cancel')">
    <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol" >
      <a-form-model-item label="分类名称" prop="name" :wrapper-col="{span: 8}">
        <a-input v-model:value="form.name" placeholder="请输入分类名称" />
      </a-form-model-item>
      <a-form-model-item label="上级分类" prop="parentId">
        <a-select v-model:value="form.parentId" :options="typeList" style="width: 40%" @select="onSelect" />
        <div class="tips">上级分类选择“无”，则新增的此分类为一级分类。</div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { addGoodsCategoryAPI, getGoodsCategoryListAPI, editGoodsCategoryAPI } from '@/request/api/physicalCard'
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      Object: {}
    }
  },
  watch: {
    visible(val) {
      this.typeList = this.$options.data().typeList
      if (val) {
        this.record.id ? Object.assign(this.form, this.record, {parentId: this.record.parent_id}) : this.form = this.$options.data().form
        this.getList()
      }
    }
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        id: '',
        name: '',
        parentId: 0, 
        state: 1
      },
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }]
      },
      typeList: [{label: '无', value: 0}]
    }
  },
  methods: {
    async getList() {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        isParent: 1
      }
      const { code, data: { data } } = await getGoodsCategoryListAPI(params)
      if (code !== 200) return
      data.forEach(item => {
        Object.assign(item, {label: item.name, value: item.id})
      })
      this.typeList = this.typeList.concat(data)
    },
    onSelect(value) {
      this.form.parentId = value
    },
    handleOk() {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return
        this.record.id ? this.edit() : this.add()
        this.$emit('ok')
      })
    },
    async add() {
      delete this.form.id
      const { code } = await addGoodsCategoryAPI(this.form)
      if (code !== 200) return
      this.$message.success('添加成功')
    },
    async edit() {
      delete this.form.parent_id
      delete this.form.parent_name
      const { code } = await editGoodsCategoryAPI(this.form)
      if (code !== 200) return
      this.$message.success('修改成功')
    }
  }
}
</script>